.card_skeleton {
  width: 20rem;
  display: flex;
  height: 30rem;
  border-radius: 1rem;
  flex-flow: column nowrap;
  box-shadow: 0 0.5rem 1.5rem -0.3rem rgba(0, 0, 0, 0.15);
  background-color: #fbfbfd;
}
.card_skeleton > header,
.card_skeleton footer {
  align-items: center;
  display: flex;
  width: 100%;
  height: 3rem;
  padding: 0 1rem;
}
.card_skeleton > header > span,
.card_skeleton footer > span {
  width: 4rem;
  height: 1.4rem;
  border-radius: 2rem;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  animation: 1.5s shine linear infinite;
  background-size: 200% 100%;
}
.card_skeleton > section.skeleton_avatar {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  padding-bottom: 0.65rem;
}
.card_skeleton > section.skeleton_avatar > div {
  width: 13rem;
  display: flex;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  animation: 1.5s shine linear infinite;
  background-size: 200% 100%;
}
.card_skeleton > section.skeleton_avatar > span {
  bottom: 0;
  width: 6rem;
  height: 1.6rem;
  position: absolute;
  border-radius: 2rem;
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  animation: 1.5s shine linear infinite;
  background-size: 200% 100%;
}
.card_skeleton > section.skeleton_idents {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  gap: 0.3rem;
  padding: 0.5rem 0;
}
.card_skeleton > section.skeleton_idents div {
  border-radius: 2rem;
  min-height: 1.3rem;
  width: 10rem;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  animation: 1.5s shine linear infinite;
  background-size: 200% 100%;
}
.card_skeleton > section.skeleton_idents div:last-child {
  min-height: 1rem;
  width: 7rem;
}
.card_skeleton > section.skeleton_skills {
  flex-grow: 1;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  gap: 0.2rem 1rem;
}
.card_skeleton > section.skeleton_skills > span {
  width: 4rem;
  height: 1.4rem;
  border-radius: 2rem;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  animation: 1.5s shine linear infinite;
  background-size: 200% 100%;
}
.card_skeleton > section.skeleton_skills > span:nth-child(odd) {
  width: 5rem;
}
.card_skeleton > footer {
  justify-content: center;
  gap: 1rem;
}
.card_skeleton > footer > span {
  width: 7rem;
}
.card_skeleton > footer hr {
  all: unset;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  animation: 1.5s shine linear infinite;
  background-size: 200% 100%;
  width: 15%;
  height: 1px;
}
@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.card {
  min-width: 20rem;
  width: 20rem;
  display: flex;
  height: 30rem;
  overflow: hidden;
  border-radius: 1rem;
  flex-flow: column nowrap;
  background-color: rgba(255, 255, 255, 0.98);
  transition: all 0.1s ease-in;
  box-shadow: 0 0.2rem 1.5rem -0.3rem rgba(0, 0, 0, 0.2);
}
.card > header,
.card footer {
  display: flex;
  width: inherit;
  min-height: 3rem;
  padding: 0 1rem;
}
.card > header {
  justify-content: space-between;
  align-items: center;
  user-select: none;
}
.card > header > span {
  color: rgba(0, 0, 0, 0.2);
  font-weight: bold;
  transition: all 0.2s ease-in;
}
.card > section.avatar {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  padding-bottom: 0.65rem;
  cursor: pointer;
}
.card > section.avatar > picture {
  pointer-events: none;
  width: 13rem;
  display: flex;
  aspect-ratio: 1;
  border-radius: 50%;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, rgb(233, 83, 218), rgb(92, 23, 219));
}
.card > section.avatar > picture span {
  width: inherit;
  height: 100%;
}
.card > section.avatar > picture span img {
  pointer-events: none;
  width: inherit;
  height: 100%;
  object-fit: cover;
}
.card > section.avatar > span {
  bottom: 0;
  transform-origin: center;
  line-height: 1;
  position: absolute;
  padding: 0.3rem 1rem;
  border-radius: 2rem;
  text-transform: uppercase;
  background-color: rgba(255, 255, 255, 0.95);
}
.card > section.idents {
  width: inherit;
  padding: 0.5rem 1rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.card > section.skills {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
}
.card > section.skills .wrapper {
  display: inherit;
  justify-content: inherit;
  align-items: inherit;
  gap: 0.5rem 0.5rem;
  flex-flow: row wrap;
}
.card > section.skills .wrapper > s {
  all: unset;
  white-space: nowrap;
  line-height: 1;
  padding: 0.3rem 0.8rem;
  border-radius: 2rem;
  font-size: 0.9rem;
  border: 1px solid #ced6e0;
  user-select: none;
}
.card > section.skills .wrapper > s.vacant {
  border-color: rgb(233, 83, 218);
  color: rgb(233, 83, 218);
}
.card > section.skills .wrapper > s.hiddenCounter {
  border-color: rgb(233, 83, 218);
  background: rgb(233, 83, 218);
  color: #fff;
}
.card > footer {
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}
.card > footer span {
  transition: all 0.2s ease-in;
}
.card > footer hr {
  width: 15%;
  height: 1px;
  border: none;
  background-color: #ccc;
  border-radius: 1rem;
  transition: all 0.2s ease-in;
}
.card > footer:hover span {
  color: rgb(233, 83, 218);
}
.card:hover {
  box-shadow: 0 0.1rem 0.6rem -0.2rem rgba(0, 0, 0, 0.3);
}
.card:hover header span {
  color: rgb(233, 83, 218);
}
.card:hover footer hr {
  width: 30%;
  background-color: rgb(233, 83, 218);
}

