.messageCard {
  padding: 3rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  align-items: center;
  flex-flow: column nowrap;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-backdrop-filter: blur(1rem) saturate(180%);
          backdrop-filter: blur(1rem) saturate(180%);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  color: #424242;
}
.messageCard > span {
  display: block;
  font-weight: bold;
}
.messageCard > span:first-child {
  font-size: 3rem;
}
.messageCard > span:last-child {
  padding-top: 1rem;
}/*# sourceMappingURL=404.module.css.map */