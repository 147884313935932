@import "../../assets/vars";

main {
	width: 100%;
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: column nowrap;
	transition: all 0.2s ease;

	//background-image: url("https://images.unsplash.com/photo-1646259831508-b50c3f973f14?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=60");
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
}

.navigation {
	position: fixed;
	bottom: 0;
	z-index: 10;
	display: flex;
	flex-flow: row nowrap;
	align-items: stretch;
	width: 100%;
	height: 4rem;
	background-color: rgba(255, 255, 255, 0.9);
	backdrop-filter: blur(1rem) saturate(160%);
	box-shadow: 0 -0.1rem 0.5rem rgba(0, 0, 0, 0.15);

	a {
		display: flex;
		flex: 1 1 auto;
		flex-flow: column nowrap;
		align-items: center;
		justify-content: center;
		text-decoration: none;
		height: 100%;
		& > svg {
			font-size: 1.5rem;
		}

		&:visited {
			color: #424242;
		}

		&[class="active"] {
			background-color: $blue;
			color: #ffffff;
		}

		&[data-link="cart"] {
			position: relative;
			s {
				all: unset;
				position: absolute;
				display: flex;
				background-color: #ff5252;
				min-width: 1.2rem;
				aspect-ratio: 1;
				border-radius: 1rem;
				justify-content: center;
				align-items: center;
				line-height: 1;
				font-weight: bold;
				color: #fbfbfd;
				font-size: 0.8rem;
				left: 50%;
				transform: translate(20%, 0%);
				top: 0.2rem;
			}
		}
		&[data-link="home"] {
			position: relative;
		}
	}
	.filterBtn {
		// position: absolute;
		// right: 0%;
		// height: 100%;
		display: flex;
		align-items: center;
		padding: 0 1rem;
		background-color: $lightBlue;
		color: #fff;
		gap: 0.5rem;
		transition: all 0.2s ease;
		cursor: pointer;
		@media (max-width: 568px) {
			& > span {
				display: none;
			}
		}
	}
}

.toolbar {
	top: 0;
	padding: 0.5rem 2rem;
	box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.15);
	justify-content: space-between;

	.logo {
		height: 2.5rem;
		width: 10rem;
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
	}
}
