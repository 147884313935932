@import "../../assets/vars";


$yellow: #FFDA1D;
$grey: #ced6e0;
$generalElPadding: 0 2rem;

.cv_canvas {
  flex-grow: 1;
  display: flex;
  width: inherit;
  padding-bottom: 2rem;
  flex-flow: column nowrap;
  background-color: rgba(255, 255, 255, .9);
  backdrop-filter: blur(1rem) saturate(180%);
  @media (min-width: 768px) {
    flex-flow: row nowrap;
  }

  .general {
    gap: 2rem 0;
    display: flex;
    position: relative;
    flex-flow: column nowrap;
    @media (min-width: 768px) {
      width: 30%;
      min-width: 23rem;
    }

    .heroSection {
      display: inherit;
      overflow: hidden;
      position: relative;
      align-items: center;
      justify-content: center;
      border-radius: 0 0 1rem 1rem;
      box-shadow: 0 .5rem .5rem -.4rem rgba(0, 0, 0, .2);

      @media (min-width: 768px) {
        gap: 1rem;
        border-radius: 0;
        box-shadow: none;
        flex-flow: row wrap;
        padding: 2rem 0 0 0;
        margin: $generalElPadding;
      }

      .desktopMeta {
        flex-grow: 1;
        display: flex;
        flex-flow: column;

        .idNumber {
          margin: 1rem 0;
          line-height: 1;
          color: #424242;
          font-weight: bold;
          padding: .3rem 1rem;
          border-radius: 2rem;
          max-width: max-content;
          background-color: $grey;
        }

        h2 {
          width: inherit;
          font-size: 2rem;
          white-space: nowrap;
        }
      }

      .meta {
        top: 0;
        z-index: 5;
      }

      > picture {
        width: 100%;
        display: flex;
        aspect-ratio: 1;
        min-width: 20rem;
        overflow: hidden;
        background-image: url("https://images.unsplash.com/photo-1618005182384-a83a8bd57fbe?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1364&q=80");

        @media (min-width: 768px) {
          width: 100%;
          flex-grow: 1;
          flex-shrink: 2;
          flex-basis: 30%;
          min-width: 10rem;
          max-width: 16rem;
          border-radius: 50%;
        }

        img {
          width: 100%;
          object-fit: cover;
        }
      }

      .idents {
        bottom: 0;
        gap: 0 1rem;
        position: absolute;
        flex-flow: row wrap;


        h2, h3 {
          white-space: nowrap;
        }
      }

      .meta, .idents {
        width: 100%;
        display: flex;
        min-height: 2rem;
        position: absolute;
        align-items: center;
        padding: .5rem 2rem;
        justify-content: space-between;
        background-color: rgba(255, 255, 255, .8);
        backdrop-filter: blur(1rem) saturate(200%);
      }

      .videoBtn {

        all: unset;
        gap: .5rem;
        bottom: 25%;
        padding: 2%;
        right: -100%;
        color: white;
        display: flex;
        line-height: 1;
        cursor: pointer;
        font-weight: bold;
        position: absolute;
        align-items: center;
        animation-delay: 1s;
        animation-duration: .5s;
        animation-name: showBtn;
        transition: all .2s ease-in;
        backdrop-filter: blur(.5rem);
        border-radius: 2rem 0 0 2rem;
        animation-fill-mode: forwards;
        border: 2px solid transparent;
        border-right: none;
        animation-timing-function: ease;
        background-color: rgba(33, 150, 243, .8);
        box-shadow: .5rem .5rem 1rem rgba(0, 0, 0, .2);

        &.hidden {
          right: 0;
          animation-duration: .5s;
          animation-name: hideBtn;
          transition: all .2s ease-in;
          animation-fill-mode: forwards;
          animation-timing-function: ease;
        }

        svg {
          font-size: 2rem;
          transition: inherit;
        }

        &:hover {
          border-color: white;

          & svg {
            transform: rotate(120deg);
          }
        }

        @keyframes showBtn {
          from {
            right: -100%;
          }

          to {
            right: 0;
          }
        }

        @keyframes hideBtn {
          from {
            right: 0;
          }

          to {
            right: -100%;
          }
        }
      }

      .playerWrapper {
        width: 100%;
        height: 100%;
        display: flex;
        position: absolute;
        align-items: center;
        justify-content: center;
        flex-flow: column nowrap;
        backdrop-filter: blur(1rem);
        background-color: rgba(0, 0, 0, .9);

        > div {
          width: 100%;
          aspect-ratio: 16/9;
        }

        svg {
          color: white;
          font-size: 5vw;
          position: absolute;
          bottom: calc((21.875% / 2) - 2.5vw + 24px); /*TODO: think about margins*/

        }
      }

    }

    .skillSection {
      gap: 1rem;
      display: flex;
      flex-flow: column nowrap;
      padding: $generalElPadding;

      > b {
        text-transform: uppercase;
      }

      .skillArray {
        display: flex;
        gap: .5rem .5rem;
        flex-flow: row wrap;

        > s {
          all: unset;
          line-height: 1;
          cursor: pointer;
          font-size: .9rem;
          padding: .3rem 1rem;
          border-radius: 2rem;
          border: 1px solid $grey;

          &:hover {
            color: white;
            background-color: dodgerblue;
          }

        }
      }
    }

    .pricingSection {
      display: flex;
      row-gap: 1rem;
      overflow: hidden;
      border-radius: .5rem;
      flex-flow: column nowrap;
      margin: $generalElPadding;
      background-color: rgba(255, 255, 255, .9);
      box-shadow: 0 .2rem .5rem rgba(0, 0, 0, .15);

      .selector {
        display: flex;
        flex-flow: row nowrap;
        border-bottom: 2px solid $yellow;

        button {
          border: none;
          flex-grow: 1;
          cursor: pointer;
          min-height: 3rem;
          padding: .5rem 1rem;
          background-color: unset;
          text-transform: capitalize;
          transition: 0.3s all ease-in;
        }

        .active {
          background: linear-gradient(90.44deg, #FFDA1D 0.5%, #FFBA1B 100.05%);
          color: #424242;
        }
      }

      .priceDetails {
        display: flex;
        align-items: center;
        flex-flow: column nowrap;

        > span {
          text-align: center;
        }

        .priceRow {
          line-height: 1;
          font-size: 3rem;
          font-weight: bold;
          width: min-content;
          position: relative;

          sup {
            left: -2rem;
            position: absolute;
          }
        }

        .priceDetails {
          color: #ccc;
          padding: .5rem;
          font-size: .85rem;
        }
      }

      .bookBtn {
        border: none;
        color: white;
        display: flex;
        padding: .5rem;
        cursor: pointer;
        min-height: 3rem;
        font-weight: bold;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        background-color: dodgerblue;
        &_inCart{
          border: none;
          color: white;
          display: flex;
          padding: .5rem;
          cursor: pointer;
          min-height: 3rem;
          font-weight: bold;
          align-items: center;
          justify-content: center;
          text-transform: uppercase;
          background-color: #00E676;
        }
      }
    }
  }

  .secondary {
    display: flex;
    flex-flow: column nowrap;
    padding: 2rem;
    gap: 2rem;
    flex-grow: 1;
    @media (min-width: 768px) {
      box-shadow: -.1rem -.5rem .5rem rgba(0, 0, 0, .15);
      .desktopPlayer {
        aspect-ratio: 16/9;
        border-radius: 1rem;
        overflow: hidden;
        max-width: 85%;
        align-self: center;
      }
      width: 70%;
    }

    .eduWrapper,
    .expWrapper {
      display: flex;
      flex-flow: column nowrap;
      row-gap: 1rem;

      > span {
        text-transform: uppercase;
        font-size: 1.5rem;
        font-weight: bold;
        text-decoration: underline;
      }

      .eduContainer,
      .expContainer {
        display: inherit;
        flex-flow: inherit;
        row-gap: 1.5rem;

        .eduItem,
        .expItem {
          display: inherit;
          flex-flow: inherit;
          row-gap: .25rem;
          border-left: 2px solid;
          border-image: linear-gradient(to bottom, transparent, dodgerblue, #FFBA1B, transparent) 1;
          padding-left: 1rem;

          .eduItem_name,
          .expItem_name {
            $rowSize: 1.1rem;

            font-size: $rowSize;

            > b {
            }

            > i {
              height: $rowSize;
              background-color: #f0f0f0;
              padding: .125rem 1rem;
              margin-left: 1rem;
              line-height: 1;
              border-radius: 2rem;
              color: #424242;
              font-style: normal;
              font-size: .85rem;
              white-space: nowrap;
            }
          }

          .eduItem_grade,
          .expItem_position {
            > b {
              color: #424242;
            }
          }

          .expItem_description {
            padding: .5rem 0;
          }

          .eduItem_fields,
          .expItem_resps {
            display: flex;
            flex-flow: row wrap;
            gap: .5rem;
            color: #424242;

            > i {
              background-color: #f0f0f0;
              padding: .2rem 1rem;
              line-height: 1;
              border-radius: 2rem;
              color: #424242;
              font-style: normal;
              font-size: .85rem;
              white-space: nowrap;
            }
          }
        }
      }
    }

  }

}

