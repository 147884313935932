$blue: rgba(233, 83, 218, 1);
$grey: rgb(206, 214, 224);
$lightBlue: rgba(90, 90, 90, 1);

$avatarBgColor: linear-gradient(
	180deg,
	rgba(233, 83, 218, 1),
	rgb(92, 23, 219)
);
$cardBgColor: rgba(255, 255, 255, 0.98);
$hiddenCounterColor: rgba(233, 83, 218, 1);
